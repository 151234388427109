import {
  Box,
  Button,
  Checkbox,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { Formik, Form, Field } from 'formik';
import clipboardImage from 'assets/clipboard.svg';
import { EXTERNAL_LINKS } from 'common/links';
import { useUpdateUserProfile } from 'api/update-user-profile';
import { useAcceptUserTerms } from '../../hooks/use-accept-user-terms';

type FormValues = {
  emailOptIn: boolean;
};

export const UserSetupFormComponent = () => {
  const updateUserProfileMutation = useUpdateUserProfile();
  const acceptUserTermsMutation = useAcceptUserTerms();
  const { formatMessage } = useIntl();

  const loading =
    updateUserProfileMutation.isLoading || acceptUserTermsMutation.isLoading;

  return (
    <StackLayout
      verticalAlignment="center"
      horizontalAlignment="center"
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
        overflow: 'auto',
      }}
    >
      <StackLayout
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          width: '360px',
        }}
      >
        <Image
          src={clipboardImage}
          alt={formatMessage({ id: 'ALT_CLIPBOARD_IMAGE' })}
          width={176}
          marginBottom="two"
        />
        <HeadingText level="2" size="xl" css={{ marginBottom: '$half' }}>
          {formatMessage({ id: 'COMPLETE_YOUR_ACCOUNT' })}
        </HeadingText>
        <Formik<FormValues>
          initialValues={{
            emailOptIn: false,
          }}
          onSubmit={async (form) => {
            if (form.emailOptIn) {
              await updateUserProfileMutation.mutateAsync(
                {
                  opted_into_marketing_communications: true,
                },
                {
                  onError() {
                    toastActions.add({
                      type: TOAST_STATUS.ERROR,
                      textId: 'ERROR_CAPTION_PROFILE',
                    });
                  },
                },
              );
            }
            await acceptUserTermsMutation.mutateAsync(undefined, {
              onError() {
                toastActions.add({
                  type: TOAST_STATUS.ERROR,
                  textId: 'ERROR_CAPTION_SAVE',
                });
              },
            });
          }}
        >
          <Form>
            <Box
              css={{ display: 'flex', flexDirection: 'column', gap: '$half' }}
            >
              <Field
                as={Checkbox}
                type="Checkbox"
                id="emailOptIn"
                name="emailOptIn"
                label={formatMessage({ id: 'EMAIL_OPT_IN_POST_AUTH' })}
              />
              <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  width="fillContainer"
                  css={{
                    fontWeight: 600,
                    background: '$primaryBackgroundDefault',
                    marginTop: '$five',
                  }}
                  type="submit"
                  disabled={loading}
                  loading={loading}
                >
                  {formatMessage({ id: 'AGREE_AND_CONTINUE' })}
                </Button>
              </Box>
              <ParagraphText
                size="sm"
                css={{
                  textAlign: 'center',
                }}
              >
                {formatMessage({ id: 'PRIVACY_POLICY_AND_TOS_POST_AUTH_1' })}{' '}
                <a
                  href={EXTERNAL_LINKS.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                >
                  {formatMessage({ id: 'PRIVACY_POLICY' })}
                </a>{' '}
                {formatMessage({ id: 'AND' })}{' '}
                <a
                  href={EXTERNAL_LINKS.TERMS_OF_SERVICES}
                  target="_blank"
                  rel="noreferrer"
                >
                  {formatMessage({ id: 'TERMS_OF_SERVICE' })}
                </a>
                .
              </ParagraphText>
            </Box>
          </Form>
        </Formik>
      </StackLayout>
    </StackLayout>
  );
};
