import { ENVIRONMENT, LeagueConfig } from '@leagueplatform/core';
/**
 * @ts-ignore is used here due to @leagueplatform/legacy-locales being a
 * temporary measure that is served as a JS utility.
 */
// @ts-ignore
import { legacyStrings } from '@leagueplatform/legacy-locales';
import { ovatientTheme } from 'themes/ovatient-metrohealth.theme';
import { EN_STRING_OVERRIDES } from 'locales/en';

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
  VITE_TENANT_ID,
  VITE_CLIENT_ID,
  VITE_AUTH0_CLIENT_CONNECTION,
  VITE_AUTH0_AUDIENCE,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN,
  client_id: VITE_AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  connection: VITE_AUTH0_CLIENT_CONNECTION,
  aud: VITE_AUTH0_AUDIENCE,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

export const config: LeagueConfig = {
  core: {
    clientId: VITE_CLIENT_ID,
    tenantId: VITE_TENANT_ID,
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    contentUrl: VITE_CONTENT_URL as string,
    appEnvironment: VITE_APP_ENV as ENVIRONMENT,
    customMethods: {
      handleLink: async (url: any) => {
        await window.open(url, '_blank', 'noreferrer');
      },
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    i18n: {
      stringOverrides: {
        default: { ...legacyStrings.default, ...EN_STRING_OVERRIDES },
        en: { ...legacyStrings.default, ...EN_STRING_OVERRIDES },
      },
    },
    ui: {
      theme: ovatientTheme,
      features: { pointsEnabled: true },
      components: {
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
    },
  },
  aboutMe: {
    showAccountBanner: false,
    showLandingPageBackButton: true,
    showOptionsMenu: true,
  },
  careDiscovery: {
    masonryAppId: {
      left: 'b2b_care_homepage',
      right: 'b2b_care_homepage_sidebar',
    },
  },
  healthProfile: {
    ratingsBarEnabled: true,
    careSdkEnabled: true,
    chatWithProfessionalEnabled: true,
  },
  careCollections: {
    showCareCollectionFeedbackComponent: true,
  },
  claims: {
    supportLink: '/support',
    filterableProperties: {
      // serviceDate: ['last_12_months', 'year_to_date', 'prior_year'],
      status: ['pending', 'approved', 'adjusted'],
    },
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: true,
      shouldShowFilterChips: true,
    },
  },
};
