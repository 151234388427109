import { BoxProps } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { FAQ } from 'common/constants';
import type { Link } from 'common/types';

export type Options = {
  heading?: string;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

export function useUserDropdownConfig(
  handleDeleteAccount: () => void,
): Options[] {
  const { formatMessage } = useIntl();

  return [
    {
      heading: 'ACCOUNT_SETTINGS',
      items: [
        {
          text: formatMessage({ id: 'PERSONAL_INFO' }),
          to: 'personal-information',
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
        {
          text: formatMessage({ id: 'COMMUNICATION' }),
          to: 'communication-preferences',
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
    {
      heading: 'SUPPORT',
      items: [
        {
          text: formatMessage({ id: 'ABOUT_MY_CARE_ANYWHERE' }),
          to: 'about-my-care',
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
        {
          text: formatMessage({ id: 'FAQ' }),
          target: '_blank',
          to: `${FAQ}`,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          icon: 'illustrativePerson',
          linkIcon: 'interfaceExternalLink',
        },
      ],
    },

    {
      items: [
        {
          text: formatMessage({ id: 'LOG_OUT' }),
          to: '/sign-out',
          color: '$onSurfaceTextCritical',
          linkProps: boldTextCriticalLink,
          icon: 'interfaceSignOut',
          linkIcon: 'interfaceChevronRight',
          onClick: handleDeleteAccount,
        },
      ],
    },
  ];
}
