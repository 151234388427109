export const EN_STRING_OVERRIDES = {
  ABOUT_MY_CARE_ANYWHERE: 'About MyCare Anywhere',
  ACCOUNT_DELETED: 'Account deleted',
  ACCOUNT_DELETED_BODY_1:
    'Please note that we are required by law to retain certain information associated with your account.',
  ACCOUNT_DELETED_BODY_2:
    'As soon as we are legally able to delete this information, it will be deleted in accordance with our data deletion and retention policy. Until then, it is protected and stored securely and is not used in any way.',
  ACCOUNT_DELETED_TITLE: 'Your account is now deleted',
  ACCOUNT_SETTINGS: 'Account',
  ACTIVITIES: 'Updates',
  ADD_PROFILE_PHOTO: 'Add profile photo',
  ADVANCE_TO_NEXT: 'Advance to next',
  AGREE_AND_CONTINUE: 'Agree and continue',
  ALT_CLIPBOARD_IMAGE: 'clipboard image on a dark blue background',
  ALT_METROHEALTH_LOGO: 'metrohealth logo',
  AND: 'and',
  APPEALS: 'Appeals',
  ARE_YOU_SURE: 'Are you sure?',
  CANCEL: 'Cancel',
  CLAIMS: 'Claims',
  CLAIMS_FILTER_VALUE_ADJUSTED: 'Adjusted',
  CLAIMS_FILTER_VALUE_APPROVED: 'Processed',
  CLAIMS_FILTER_VALUE_PENDING: 'Processing',
  CLAIMS_STATUS_DEFINITION_APPROVED: 'Processed means the claim is approved.',
  CLOSE: 'Close',
  COMMUNICATION: 'Communication',
  COMMUNICATION_PREFERENCES_CHECKBOX_EMAIL_OPT_IN:
    'I’d like to receive occasional emails with reminders and tips',
  COMMUNICATION_PREFERENCES_EMAIL_DISCLAIMER:
    'Email is not a secure method of communicating and carry some risk of being read by a third party. Email may be recurring and include personal health information.',
  COMMUNICATION_PREFERENCES_NOTIFICATION_ERROR:
    '"An error occurred while updating your email notification preferences. Please try again later.",',
  COMMUNICATION_PREFERENCES_UPDATE_EMAIL:
    'Contact a Care Advocate to update your email',
  COMMUNICATION_PREFERENCES_USER_EMAIL_NOTIFICATION:
    'You’re getting notified on',
  COMMUNICATION_PREFERENCES_VISIT_OUR_TERMS: 'Visit our terms',
  COMPLETE_YOUR_ACCOUNT: 'Complete your account',
  CONTACT_CARE_ADVOCATE:
    'Contact a Care Advocate for making any changes to the information below:',
  CONTINUE: 'Continue',
  CONTINUE_WITH_EPIC: 'Continue with EPIC',
  DATE_OF_BIRTH: 'Date of Birth',
  DELETE_ACCOUNT: 'Delete account',
  DELETE_ACCOUNT_BODY_1:
    "By deleting your account, you'll lose access to the metrohealth app. You will still have access to your health plan. If you would like to delete your metrohealth username and password then please call 1-800-223-9730.",
  DELETE_ACCOUNT_BODY_2:
    'If you access the app again and login with your metrohealth credentials a new account will be created on the metrohealth app. ',
  DELETE_ACCOUNT_TITLE: 'Delete my account',
  DELETE_YOUR_ACCOUNT: 'Delete your account',
  DONT_HAVE_AN_ACCOUNT: 'Don’t have an account?',
  DISCLAIMERS: 'Disclaimers',
  DISCLAIMER_COPY: 'Disclaimer copy',
  DISPLAY_NAME_UPDATED: 'Successfully updated your display name',
  DOCUMENT_TITLE: '{title} - Ovatient',
  EDIT_DISPLAY_NAME: 'Edit display name',
  EMAIL_ADDRESS: 'Email address',
  EMAIL_OPT_IN_POST_AUTH:
    'I’d like to receive occasional emails with progress updates, earned badges, and tips to get the most out of this app.',
  ERROR_ACCEPTING_TERMS: 'error accepting terms',
  ERROR_CAPTION_BACKEND:
    'There was a problem loading data.\nPlease try again later.',
  ERROR_CAPTION_NO_CONNECTION:
    'Please check your internet.\nThis page will automatically reload when you reconnect.',
  ERROR_CAPTION_PROFILE: 'Error updating user profile.',
  ERROR_CAPTION_SAVE: 'Error saving data. Please try again.',
  ERROR_DISPLAY_NAME_EMPTY: "Display name can't be empty",
  ERROR_DISPLAY_NAME_ONLY_LETTERS: 'Display name can only contain letters',
  ERROR_DISPLAY_NAME_TOO_LONG: 'Display name is too long',
  EXCEPTION_DISPLAY_NAME_FAILED_TO_SAVE:
    'Something went wrong while updating your display name',
  EXCEPTION_PROFILE_PICTURE_FAILED_TO_SAVE:
    'Something went wrong while updating your profile picture',
  FAQ: 'FAQs',
  FIRST_NAME: 'First name',
  FORMS_CENTER: 'Forms Center',
  FORMS_CENTER_SUBTITLE:
    'Complete a claim form only if your provider isn’t filing one for you.',
  GRAMM_LEACH_BILLEY_ACT_NOTICE: 'Gramm-Leach-Billey Act Notice',
  GRIEVANCES: 'Grievances',
  HIPPA_NOTICE_OF_PRIVACY_PRACTICES: 'HIPPA Notice of Privacy Practices',
  I_UNDERSTAND: 'I understand',
  LANGUAGE_ASSISTANCE: 'Language Assistance',
  LAST_NAME: 'Last name',
  LOGIN: 'Login',
  LOG_OUT: 'Sign out',
  LOREM_IPSUM:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  MARK_AS_DONE: 'Mark As Completed',
  NEVER_MIND: 'Never mind',
  NONDISCRIMINATION_NOTICE: 'Nondiscrimination Notice',
  PERSONAL_INFO: 'Personal info',
  PHONE_NUMBER: 'Phone number',
  PRIVACY_AND_LEGAL_DOCUMENTS: 'Privacy and Legal Documents',
  PRIVACY_FORMS: 'Privacy Forms',
  PRIVACY_NOTICE: 'Privacy Notice',
  PRIVACY_POLICY: 'Privacy Policy',
  PRIVACY_POLICY_AND_TOS_POST_AUTH_1: 'By continuing, you agree to our',
  PROFILE_PICTURE_UPDATED: 'Successfully updated your profile picture',
  RATE_US_IN_THE_APP_STORE: 'Rate us in the App Store',
  REGISTER_NOW: 'Register now',
  SAVE: 'Save',
  SET_DISPLAY_NAME: 'Set your display name as',
  SET_DISPLAY_NAME_DESCRIPTION:
    'This name will only be used to communicate with you on your emails, notifications, and greetings in the app. All other communication will use your legal name. ',
  SUPPORT: 'Get Support',
  TERMS_OF_SERVICE: 'Terms of Service',
  UPDATE_PROFILE_PHOTO: 'Update profile photo',
  USER_AVATAR_FILE_TOO_LARGE_ERROR:
    'The file you selected is too large. Please select a file under {fileSize} MB.',
  WALLET: 'Benefits',
  YES_DELETE_ACCOUNT: 'Yes, delete this account',
};
