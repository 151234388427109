/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';

import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';

import {
  AboutMeRoutes,
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  ClaimsRoutes,
  PersonalInformationPage,
  CommunicationPreferencesPage,
  AboutMyCarePage,
} from 'components/LazyRoutes';

import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

import ErrorPage from 'pages/Error404';
import { useConfigWithFeatureFlags } from 'hooks/use-config-with-feature-flags';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';
import { BasePageProvider } from './base-page-provider/base-page-provider.component';
import { OnboardingWrapper } from './onboarding/onboarding-wrapper';

function AppRoutes() {
  useConfigWithFeatureFlags();
  return (
    <Switch>
      <Route path="/sign-in" component={AuthViewLogin} />
      <Route path="/sign-out" component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthSessionContainer>
        <OnboardingWrapper>
          <BasePageProvider>
            <Switch>
              <Route path="/home" component={Home} exact key="home" />
              <Route
                path="/personal-information"
                component={PersonalInformationPage}
                exact
                key="personal-information"
              />
              <Route
                path="/communication-preferences"
                component={CommunicationPreferencesPage}
                exact
                key="communication-preferences"
              />
              <Route
                path="/about-my-care"
                component={AboutMyCarePage}
                exact
                key="about-my-care"
              />
              <Route
                path={[
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                  getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                  getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
                ]}
                component={HealthJourneyRoutes}
                key="health-journey"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                component={AboutMeRoutes}
                key="about-me"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
                component={CareCollectionsRoutes}
                key="care-collections"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                component={CareDiscoveryRoutes}
                key="care-discovery"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
                component={HealthNeedPages}
                key="health-needs"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
                component={WalletRoutes}
                key="wallet"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                component={HealthProfileRoutes}
                key="health-profile"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
                component={OnboardingRoutesWithProvider}
                key="onboarding"
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                component={ClaimsRoutes}
                key="claims"
              />
              <Route component={ErrorPage} />
            </Switch>
          </BasePageProvider>
        </OnboardingWrapper>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
