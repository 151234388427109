import { useIntl } from '@leagueplatform/locales';
import { captureAppError } from '@leagueplatform/observability';
import { SENTRY_CONSTANTS, getError } from '@ovatient-web/shared-module';
import { useMutation } from 'react-query';
import { acceptUserTerms } from '../api/accept-user-terms';

export const useAcceptUserTerms = () => {
  const { formatMessage } = useIntl();
  const ERROR_TEXT: string = formatMessage({ id: 'ERROR_ACCEPTING_TERMS' });

  const acceptTermsMutation = useMutation(() => acceptUserTerms(), {
    onError(error) {
      captureAppError(getError(error), {
        ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
        errorName: ERROR_TEXT,
      });
    },
  });

  return acceptTermsMutation;
};
